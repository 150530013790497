
import SectionSpecsV1 from './specs/SectionSpecsV1'
import SectionSpecsV2 from './specs/SectionSpecsV2'
export default {
  name: 'SectionSpecs',
  components: {},
  props: {
    variant: {
      required: true,
      type: String,
      default: ''
    },
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      componentVariant: null
    }
  },
  watch: {
    variant: function (newVariant, oldVariant) {
      this.handleVariant(newVariant)
    }
  },
  created() {
    this.handleVariant(this.variant)
  },
  methods: {
    handleVariant(variant) {
      switch (variant) {
        case 'v2':
          this.componentVariant = SectionSpecsV2
          break
        default:
          this.componentVariant = SectionSpecsV1
          break
      }
    }
  }
}
